import { useEffect, useState } from 'react';

import {
	FilmsBlok,
	StoryBlok,
	FounderBlok,
	FoundingMembers,
	MembersBenefits,
	FAQBlok,
	ContactUs,
	StartAnimation,
	VideoBlok,
	MobileVideoBlock,
} from '../component';

import Navbar from '../component/Nav/Navbar';
import { navigation } from '../utils/constants';
import SocialMedia from '../component/Nav/SocialMedia';

const getWindowsDimension = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
};

const Home = () => {

	// *UPDATE SCREEN SIZE WHEN SCREEN/VIEW PORT RESIZES
	const [screenSize, setScreenSize] = useState(getWindowsDimension());

	useEffect(() => {
		const handleResize = () => {
			setScreenSize(getWindowsDimension());
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	

	return (
		<>
			<Navbar sections={navigation} />
			<div id="home" style={{ maxWidth: '100%', position: 'relative' }}>
				{screenSize.width >= 900 ? <VideoBlok /> : <MobileVideoBlock />}
			</div>
			<section className='global-content'>
				<FilmsBlok />
				<StoryBlok />
				<FounderBlok />
				<FoundingMembers />
				<MembersBenefits />
				<FAQBlok />
				<ContactUs />
			</section>
			<StartAnimation />
		</>
	);
}

export default Home;
