import React from 'react';
import './App.scss';
import Home from './pages/home';
import SmoothScroll from './hook/SmoothScroll';

function App() {

  return (
    <SmoothScroll>
      <div className="App">
        <Home></Home>
      </div>
    </SmoothScroll>
  );
}

export default App;
