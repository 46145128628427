import React, { useState } from 'react';
import './footer.scss';
import dlogo from "../../images/bottomlogo.svg"

import { Link as ScrollLink } from 'react-scroll';
import { navigation } from '../../utils/constants';
const Footer = () => {
  const [navActive, setNavActive] = useState<boolean>(false);

  /**
     * Hiding navigation on clicking a nav link (important in mobie view)
     */
  const handleLinkClick = (link: any) => {
    setNavActive(false);
  };
  return (
    <footer className="footer-section">
      {/* <div className="footer-top">
        <h3>Connect With Us</h3>
        <div className="footer-icons">
          <a href="#" aria-label="Instagram">
            <img src={socImg1} alt='socImg1'/>
          </a>
          <a href="#" aria-label="X (formerly Twitter)">
          <img src={socImg2} alt='socImg1'/>

          </a>
          <a href="#" aria-label="Another Platform">
          <img src={socImg3} alt='socImg1'/>

          </a>
        </div>
      </div> */}
      <div className="footer-menu">
        {navigation.map(({ text, link }, i) =>
          <ScrollLink
            activeClass="active"
            smooth
            spy
            to={link}
            offset={0}
            onClick={() => {
              handleLinkClick(link)
            }}
            key={i}
          >
            {text}
          </ScrollLink>
        )
        }
      </div>
      <div className='footer-line' />
      <div className="footer-bottom">
        <p>Created by </p>
        <a href="https://www.digidzign.com" target="_blank" rel="noopener noreferrer"><img src={dlogo} alt="" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
