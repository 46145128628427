import { useState, useEffect, useRef } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { motion, AnimatePresence } from 'framer-motion';
import menuCloseButton from "../../images/menu-close-button.svg"
import burgerButtonIcon from "../../images/burger-button-icon.svg"

import {
  useNavStore,
} from '../../utils/config';


import { navigation } from '../../utils/constants';
import Logo from './Logo';
import './nav.scss';
import { link } from 'fs';
import SocialMedia from './SocialMedia';

const getWindowsDimension = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const BurgerMenu = ({ setIsOpenMenu }: any) => {
  return (
    <div className="menu-burger-button-conteainer" onClick={() => { setIsOpenMenu(true) }}>
      <img src={burgerButtonIcon} alt="menuCloseButton" />
    </div>
  )
}
const CloseMenu = ({ setIsOpenMenu }: any) => {
  return (
    <div className="menu-close-button-conteainer" onClick={() => { setIsOpenMenu(false) }} style={{zIndex:50,position:"absolute",right:"0px" , top:'-30px'}}>
      <img src={menuCloseButton} alt="menuCloseButton" />
    </div>
  ) 
}




const Navbar = ({ sectionInView }: any) => {
  const navId = useNavStore((state: any) => state.navId);
  const updateNavId = useNavStore((state: any) => state.updateNavId);
  const [showingIFrame, setShowingIFrame] = useState(false);
  const [screenSize, setScreenSize] = useState(getWindowsDimension());

  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null); // Reference to the menu container
  const [navActive, setNavActive] = useState<boolean>(false);


  /**
   * Hiding navigation on clicking a nav link (important in mobie view)
   */
  const handleLinkClick = (link: any) => {
    setNavActive(false);
    updateNavId(link)
  };

  /**
   * Hiding navigation on clicking a nav link (important in mobie view)
   */
  const handleInternalLinkClick = async () => {
    setNavActive(false);
    window.scroll(0, 0);
  };

  // *UPDATE SCREEN SIZE WHEN SCREEN/VIEW PORT RESIZES
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getWindowsDimension());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigateToFunction = async (link: any) => {
    window.scrollTo({ top: 0 });
    setScrolledOffHero(true);
    updateNavId(link);
  };

  const [scrolledOffHero, setScrolledOffHero] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (screenSize.width >= 768) {
        setScrolledOffHero(true);
      } else {
        setScrolledOffHero(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpenMenu(false); // Close menu if clicked outside
    }
  };

  useEffect(() => {
    if (isOpenMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenMenu])

  // Define your animation variants
  const navVariants = {
    hidden: { opacity: 0, y: -20, scale: 0.95 }, // Start hidden, slide up, and scale down
    visible: { opacity: 1, y: 0, scale: 1 }, // Fully visible, at original position and scale
  };


  return (
    <>

      <AnimatePresence>

        {scrolledOffHero && (

          <motion.div
            initial={{ y: 0, opacity: 1 }}
            whileInView={{ y: [0, 0], opacity: [1, 1] }}
            exit={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className={`${showingIFrame ? 'w-[75vw]' : 'w-full'
              } navbar nav`}
          >

            <div className="navbar-container">
              <div className="Logo">
                <Logo />
              </div>

              <div
                className={`social-med`}
              >
                <SocialMedia />
              </div>


              <div
                className={`hidden ${!showingIFrame ? 'lg:flex' : ''
                  } navigation`}
              >

                {

                  isOpenMenu ? (
                    <>
                      <CloseMenu setIsOpenMenu={setIsOpenMenu} />
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={navVariants}
                        transition={{ duration: 0.3 }} // Adjust duration as needed
                        className="naviation-bottom" ref={menuRef}>
                        {navigation.map(({ text, link }, i) =>
                          <ScrollLink
                            activeClass="active"
                            smooth
                            spy
                            to={link}
                            offset={0}
                            onClick={() => {
                              handleLinkClick(link)
                            }}
                          >
                            {text}
                          </ScrollLink>
                        )
                        }
                      </motion.div>
                    </>
                  )
                    :
                    <BurgerMenu setIsOpenMenu={setIsOpenMenu} />
                }
              </div>
            </div>
          </motion.div>

        )};

      </AnimatePresence>
    </>
  );
}

export default Navbar;
