import { useEffect, useRef, useState } from "react";
import "./videoBlok.scss"
import { useInView } from "framer-motion";
import video from '../../images/video.mp4';
import "./MobileBlock.scss";

const MobileVideoBlock = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);


  useEffect(() => {
    const timer = setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play().catch(error => {
          console.error("Failed to play video:", error);
        });
      }
    }, 2000); // 1 second delay

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  return (
    <section className={`video-blok-container sticky`}>
      <div className="video-blok-iframe-content " >
        <h1>
          <span className="video-blok-anumation-text-1">Creating a</span>
          <br />
          <span className="video-blok-anumation-text-2"> win-win world</span>
          <br />
          <span className="video-blok-anumation-text-3"> together!</span>
        </h1>
        <iframe className="elementor-video-iframe" title="vimeo Video Player"
        style={{ zIndex: 4 }}
          allowFullScreen
          src="https://player.vimeo.com/video/911408281?autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=0&title=0&frameborder=0&byline=0&h=98b4bae6b5#t="
        ></iframe>
      </div>
      <div className='overlay-gradient-bottom hide-desktop' style={{ zIndex: 1 }}></div>
    </section>
  )
}
export default MobileVideoBlock;