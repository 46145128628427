import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const useStickyScroll = (sectionRef: React.RefObject<HTMLElement | null>) => {
    useEffect(() => {
        const section = sectionRef.current;
        if (!section) return;

        // Set the initial styles for the section
        gsap.set(section, { position: 'relative' });
        const scrollTriggerInstance = ScrollTrigger.create({
            trigger: section,
            start: 'top top', // Start when the top of the section hits the top of the viewport
            end: `+=${section.scrollHeight}`, // End when the bottom of the section hits the top of the viewport
            onEnter: () => {
                gsap.to(section, { position: 'sticky', top: 0 });
            },
            onLeave: () => {
                gsap.to(section, { position: 'relative' });
            },
            onEnterBack: () => {
                gsap.to(section, { position: 'sticky', top: 0 });
            },
            onLeaveBack: () => {
                gsap.to(section, { position: 'relative' });
            },
        });

        // Cleanup on component unmount
        return () => {
            scrollTriggerInstance.kill();
        };
    }, [sectionRef]);
};

export default useStickyScroll;
