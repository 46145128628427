// * NAVIGATION
export const navigation = [
	{
		text: 'Home',
		link: 'home',
	},
	{
		text: 'About',
		link: 'about',
	},
	{
		text: 'Community',
		link: 'community',
	},
	{
		text: 'FAQ',
		link: 'faq',
	},
	{
		text: 'Join Us',
		link: 'contact',
	},
];