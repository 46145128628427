import React, { useState } from 'react';
import emailjs from '@emailjs/browser';


const SERVICE_ID = "**************";
const TEMPLATE_ID = "*******";
const USER_ID = "****************";

const ContactForm = () => {

    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
    });
    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };
    
    const handleSubmit = (e:any) => {
        e.preventDefault();
        emailjs.send(
        'service_w9o12v1',
        'template_ams6m1y',
        form,
        'o4wz1-Qfact2dZ39s'
        )
        .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
            alert("Your message has been sent successfully!");
            setForm({ name: '', email: '', phone: '' });
        })
        .catch((err) => {
            console.log('FAILED...', err);
            alert("An error occurred. Please try again.");
        });
    };

    return (
        <>
        <form className="contact-form" onSubmit={handleSubmit}>
            <input type="text" name="name" placeholder="Name" value={form.name} required onChange={handleChange}/>
            <input type="tel" name="phone" placeholder="Phone number" value={form.phone} required onChange={handleChange}/>
            <input type="email" name="email" placeholder="Email" value={form.email} required onChange={handleChange}/>
            <input type="text" name="subject" placeholder="Subject" required onChange={handleChange}/>
            <button type="submit" >Contact Us</button>
        </form>
        </>
    );
}

export default ContactForm;