import socialImg3 from "../../images/social-img-3.svg"
import socialImg2 from "../../images/social-img-2.svg"
import socialImg1 from "../../images/social-img-1.svg"
import "./SocialMedia.scss"

const SocialMedia = () => {
  return (
    <div className="social-media-container">
      <a href="https://www.instagram.com/affectchange_/" target="_blank">
        <img src={socialImg1} alt="socialImg1" />
      </a>
      <a href="https://x.com/Affectchange_" target="_blank">
        <img src={socialImg2} alt="socialImg2" />
      </a>
      <a href="https://www.facebook.com/share/N6qjY125ogkMXW9E/?mibextid=LQQJ4d" target="_blank">
        <img src={socialImg3} alt="socialImg3" />
      </a>
    </div>
  )
}

export default SocialMedia