import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useNavStore = create((set) => ({
	navId: 'home',
	updateNavId: (id:string) => set({ navId: id }),
}));

export const useCallNowStore = create((set) => ({
	showPopup: false,
	updateshowPopup: (bool :  boolean) => set({ showPopup: bool }),
}));

export const useContainerWidthStore = create((set) => ({
	width: null,
	updateWidth: (width :  number) => set(() => ({ width: width })),
}));

export const useAboutWidthStore = create((set) => ({
	width: null,
	updateWidth: (width : number) => set(() => ({ width: width })),
}));

export const useValuesWidthStore = create((set) => ({
	width: null,
	updateWidth: (width  : number) => set(() => ({ width: width })),
}));

interface ToggleIFrameStore {
    toggleIFrame: boolean;
    updateToggleIFrame: () => void;
    showIframe: () => void;
    closeIframe: () => void;
}
export const useToggleIFrameStore = create<ToggleIFrameStore>((set) => ({
    toggleIFrame: false,
    updateToggleIFrame: () => set((state) => ({ toggleIFrame: !state.toggleIFrame })),
    showIframe: () => set({ toggleIFrame: true }),
    closeIframe: () => set({ toggleIFrame: false }),
}));