import { useEffect, useRef, useState } from "react";
import "./videoBlok.scss";
import video from '../../images/video.mp4';
import loadingImage from '../../images/loading.jpg'; // Replace with your loading image path
import bannerImage from "../../images/banner.jpg";
import soundOn from "../../images/sound-on-icon.svg"; // Replace with your play button image path
import soundOff from "../../images/sound-off-icon.svg"; // Replace with your play button image path

// import playbutton from "../../images/playbutton.svg"; // Replace with your play button image path


const VideoBlok = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // State to track loading
  const [isMuted, setIsMuted] = useState(true); // Start with muted state

  useEffect(() => {
    const timer = setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play().then(() => {
          setIsPlaying(true);
        }).catch(error => {
          console.error("Failed to play video:", error);
        });
      }
      setIsLoading(false); // Hide the image and show the video
    }, 1000); // 1 second delay

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  const handleVideoClick = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted; // Toggle muted state
      setIsMuted(!isMuted); // Update state
    }
  };

  return (
    <section className={`video-blok-container sticky`}>
      <div className="video-blok-iframe-content">
        {isLoading ? (
          <img src={bannerImage} alt="Loading" className="loading-image" /> // Show loading image
        ) : (
          <video
            className="full-screen-video"
            ref={videoRef}
            autoPlay
            loop
            muted={isMuted} // Control muted state
            onClick={handleVideoClick} // Click to toggle mute
          >
            <source src={video} type="video/mp4" />
          </video>
        )}
        {isMuted ?
        
         <img src={soundOn} alt="Play Button" className="play-button" onClick={handleVideoClick} />
          :
          <img src={soundOff} alt="Play Button" className="play-button" onClick={handleVideoClick} />
        }
      </div>
      <div className='overlay-gradient-bottom' style={{ zIndex: 1 }}></div>
    </section>
  );
};

export default VideoBlok;
