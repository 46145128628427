import React from "react";
import logo from '../../images/logo.svg';
import mobile from '../../images/MobileLogo.svg';

const Logo = ()=>{
    return (
        <>
        <a href="/#home" className="pointer-events-auto">
            <img src={logo} className="h-[40px] lg:h-[50px] object-cover desktop" />
            <img src={mobile} className="h-[40px] lg:h-[50px] object-cover mobile" />
        </a>
        </>
    );
}
export default Logo;